import { Select } from "antd";
import DebugPrinter from "components/Debug/DebugPrinter";
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import useFetchDataWithOrg from "hooks/useFetchDataWithOrg";
import useFetchEntities from "hooks/useFetchEntities";
import useFetchEntity from "hooks/useFetchEntity";
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import { useMemo } from "react";
import usePersistentStore from 'store/persistentStore';
import { BASE_URL } from "utils/apiFunctions";
import MarginEditor from "./Filters/MarginEditor";
import PhaseSelector from "./Filters/PhaseSelector";
import TeamPlayerSelector from "./Filters/TeamPlayerSelector";
import IGImg from "./Parts/IGImg";
import PlayerAward from "./Parts/PlayerAward";

const IG16_Award = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const teamPlayer = usePersistentStore((state) => state.get('ig-award-teamplayer'));
  const setTeamPlayer = usePersistentStore((state) => state.set('ig-award-teamplayer'));
  const award = usePersistentStore((state) => state.get('ig-award-award'));
  const setAward = usePersistentStore((state) => state.set('ig-award-award'));

  const size = usePersistentStore((state) => state.get('ig-award-size'));
  const setSize = usePersistentStore((state) => state.set('ig-award-size'));
  const SIZE_PRESETS = ['CUSTOM'];
  const DEFAULT_SIZE = { width: 860, height: 896, preset: 'CUSTOM' };

  const igname = 'award';
  const marginDefaults = { top: 0, left: 0, bottom: 0, right: 0};
  const bgImgMargin = usePersistentStore((state) => state.get(`ig-${igname}-margin`)) ?? marginDefaults;
  const setBgImgMargin = usePersistentStore((state) => state.set(`ig-${igname}-margin`));

  const groupId = membership?.groupId;
  const seasonId = membership?.seasonId;
  const { phaseIdsAll } = usePhaseIdTranslator(membership);

  const { data: groupDetail } = useFetchEntity('Group', groupId, 'Season>Competition');
  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');
  const { data: teamDetail } = useFetchEntity(teamPlayer?.teamId && 'Team', teamPlayer?.teamId);
  const { data: awardDetail } = useFetchEntity(award?.awardId && 'Award', award?.awardId);
  const playersStats = useFetchDataWithOrg((phaseIdsAll)
    ? `/admin/tablePlayersStats?phaseIds=${phaseIdsAll.join(',')}`
    : null);

  const { data: awards } = useFetchEntities('Award', { onlyAttributes: ['awardId', 'name', 'logoUrl'] });

  const handleAwardChange = (awardId) => {
    const award = awards.find(a => a.awardId === awardId);
    setAward(award);
  }

  // prepare data

  const metadata = useMemo(() => {
    var playerStats = playersStats?.data?.find(p => p.PlayerId === teamPlayer?.playerId);;
    return {
      AwardLogo: awardDetail?.logoUrl?.replace("[size]", "cropped_md"),
      AwardName: awardDetail?.name,
      AwardDescription: awardDetail?.description,
      GroupName: groupDetail?.name,
      SeasonName: seasonDetail?.name,
      CompetitionName: seasonDetail?.Competition?.name,
      CompetitionLogo:
        seasonDetail?.Competition?.logoUrl?.replace(
          "[size]",
          "cropped_md",
        ),
      PlayerFirstName: teamPlayer?.firstName ?? "",
      PlayerLastName: teamPlayer?.lastName ?? "",
      PlayerLogo: playerStats?.PlayerLogo?.replace("[size]", "cropped_md"),
      TeamName: teamDetail?.name,
      TeamLogo: teamDetail?.logoUrl?.replace("[size]", "cropped_md"),
      DivisionName: groupDetail?.name,
      Player: {
        GP: playerStats?.GP,
        G: playerStats?.G,
        A: playerStats?.A,
        PTS: playerStats?.P,
        PIM: playerStats?.PIM,
        Stars: playerStats?.Stars,
      },
      PlayerPicture: teamPlayer?.playerId ? BASE_URL + `/admin/logo?entity=Player&id=${teamPlayer.playerId}&size=cropped_full` : null

    };
  }, [awardDetail, seasonDetail, groupDetail, teamPlayer, playersStats, teamDetail]);


  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Team/Player:</span>
        <TeamPlayerSelector value={teamPlayer} onChange={setTeamPlayer} />

        <span>Award:</span>
        <Select
          style={{ width: 200 }}
          placeholder="Select an award"
          value={award?.awardId}
          onChange={handleAwardChange}>
          {awards?.map((award) => (
            <Select.Option key={award.awardId} value={award.awardId}>
              {award.name}
            </Select.Option>
          ))}
        </Select>

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <span>Margin:</span>
        <div className="flex gap-2 items-center">
          <MarginEditor
            margin={bgImgMargin}
            setMargin={setBgImgMargin}
            defaults={marginDefaults}
          />
        </div>

        <div />
      </div>

      {showData && (<DebugPrinter data={[groupDetail, playersStats, metadata]}></DebugPrinter>)}

      <article
        ref={exportRef}
        className="mx-auto bg-hockey-page-bg text-center  font-header text-white"
        style={{
          width: `${size?.width}px`,
          height: `${size?.height}px`,
          paddingTop: `${bgImgMargin.top}px`,
          paddingLeft: `${bgImgMargin.left}px`,
          paddingRight: `${bgImgMargin.right}px`,
          paddingBottom: `${bgImgMargin.bottom}px`,
        }}
      >
        <div className="p-4">
          <IGImg
            src="/images/awards-header.png"
            className="w-full border-b-2 border-white"
            alt=""
          />
        </div>

        <h1 className="mb-4 text-8xl">{metadata.AwardName}</h1>
        <PlayerAward data={metadata} />

        <div className="p-4 pt-2">
          <IGImg src="/images/light-bar.png" className="w-full" alt="" />
        </div>
      </article>

    </>
  );
};

export default IG16_Award;

import { Button, Card, Divider, Modal, Progress, Segmented, Tooltip, message } from 'antd';
import EntityLogo from 'components/EntityLogo';
import GameEvents from 'components/Game/GameEvents';
import HmsFormLayout from 'components/HmsFormLayout';
import HmsStatusTag from 'components/HmsStatusTag';
import HmsTabbedTables from 'components/HmsTabbedTables';
import GameEventModal from 'components/Modals/GameEventModal';
import dayjs from 'dayjs';
import useEntities from 'hooks/useEntities';
import useFetchEntity from 'hooks/useFetchEntity';
import useFields from 'hooks/useFields';
import useLists from 'hooks/useLists';
import useLoadEntity from 'hooks/useLoadEntity';
import usePageTitle from 'hooks/usePageTitle';
import useSearchParamsMulti from 'hooks/useSearchParamsMulti';
import { useEffect, useState } from 'react';
import useInterval from 'use-interval';
import { formatDate, formatTime } from 'utils/generateEntityColumns';
import icons from 'utils/icons';
const duration = require('dayjs/plugin/duration'); // Import the duration plugin
dayjs.extend(duration); // Extend dayjs with the duration plugin

const GamePlay = () => {
  const entities = useEntities();
  const fields = useFields();
  const entity = entities.Game;
  const [modalData, setModalData] = useState(null);
  const { events, ListPeriod } = useLists();
  const { useSearchParamSingle } = useSearchParamsMulti();
  const [selectedPeriod, setSelectedPeriod] = useSearchParamSingle('selectedPeriod', null);
  const { id, isLoading, isError, isNotFound, data, relationsData, reload } = useLoadEntity({
    entity,
    relations: [
      'Lineups',
      'Lineups>Player',
      'Lineups>ListPosition',
    ]
  });
  const { isLoading: gameEventsLoading, isReloading: gameEventsReloading, data: gameEventsData, reload: gameEventsReload } = useFetchEntity(entity.name, id,
    [
      'GameEventGoals',
      'GameEventGoals>ScoredByPlayer',
      'GameEventGoals>ScoredByTeam',
      'GameEventGoals>AssistedBy1Player',
      'GameEventGoals>AssistedBy2Player',
      'GameEventGoals>AllowedByPlayer',
      'GameEventSaves',
      'GameEventSaves>SavedByTeam',
      'GameEventSaves>SavedByPlayer',
      'GameEventPenalties',
      'GameEventPenalties>PenalizedPlayer',
      'GameEventPenalties>PenalizedTeam',
      'GameEventPenalties>ListPenaltyType',
      'GameEventPenalties>ListPenaltySubtype',
      'GameEventInjuries',
      'GameEventInjuries>InjuredPlayer',
      'GameEventInjuries>InjuredTeam',
      'GameEventInjuries>ListInjuryType',
      'GameEventFaceOffs',
      'GameEventFaceOffs>WonByPlayer',
      'GameEventFaceOffs>WonByTeam',
      'GameEventFaceOffs>LostByPlayer',
      'GameEventPenaltyshots',
      'GameEventPenaltyshots>ExecutedByPlayer',
      'GameEventPenaltyshots>ExecutedByTeam',
      'GameEventPenaltyshots>FacedAgainstPlayer',
    ].join(','),
    {
      separateIncludes: true
    }
  )
  const [messageApi, messageContextHolder] = message.useMessage();
  const [isGameEventSaving, setIsGameEventSaving] = useState(false);
  usePageTitle('Game Play')
  const [isFocused, setIsFocused] = useState(true);

  useEffect(() => {
    const handleFocus = () => setIsFocused(true);
    const handleBlur = () => setIsFocused(false);

    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  // console.log('ListPeriod', ListPeriod);

  const periods = ListPeriod
    .map(lp => ({
      name: lp.name,
      short: lp.code,
      duration: lp.duration,
      index: lp.priority,
    }));
  periods.sort((a, b) => a.index - b.index);

  const periodOptions = ['NOT PLAYED', ...periods.map(p => p.name), 'FINISHED'];

  useEffect(() => {
    if (data) {
      if (data.status == 'RUNNING') {
        if (selectedPeriod == 'NOT PLAYER' || selectedPeriod == 'FINISHED') {
          setSelectedPeriod(periods?.[0]?.name);
        } else {
          // keep it as the user has selected
        }
      } else {
        setSelectedPeriod(data.status);
      }
    }
  }, [data])

  const gameEvents = gameEventsLoading ? null :
    [
      ...(gameEventsData?.GameEventGoals ?? []).map(r => ({ entity: 'GameEventGoal', key: r.gameEventGoalId, ...r })),
      ...(gameEventsData?.GameEventSaves ?? []).map(r => ({ entity: 'GameEventSave', key: r.gameEventSaveId, ...r })),
      ...(gameEventsData?.GameEventPenalties ?? []).map(r => ({ entity: 'GameEventPenalty', key: r.gameEventPenaltyId, ...r })),
      ...(gameEventsData?.GameEventInjuries ?? []).map(r => ({ entity: 'GameEventInjury', key: r.gameEventInjuryId, ...r })),
      ...(gameEventsData?.GameEventFaceOffs ?? []).map(r => ({ entity: 'GameEventFaceOff', key: r.gameEventFaceOffId, ...r })),
      ...(gameEventsData?.GameEventPenaltyshots ?? []).map(r => ({ entity: 'GameEventPenaltyshot', key: r.gameEventPenaltyshotId, ...r })),
    ];

  const stats = {
    [data?.homeTeamId]: {
      'Goals': gameEvents?.filter(e => e.scoredByTeamId === data?.homeTeamId && e.entity === 'GameEventGoal')?.length,
      'Penaltyshots': gameEvents?.filter(e => e.executedByTeamId === data?.homeTeamId && e.entity === 'GameEventPenaltyshot')?.length,
      'Saves': gameEvents?.filter(e => e.savedByTeamId === data?.homeTeamId && e.entity === 'GameEventSave')?.length,
      'FaceOffs': gameEvents?.filter(e => e.wonByTeamId === data?.homeTeamId && e.entity === 'GameEventFaceOff')?.length,
      'Penalties': gameEvents?.filter(e => e.penalizedTeamId === data?.homeTeamId && e.entity === 'GameEventPenalty')?.length,
    },
    [data?.awayTeamId]: {
      'Goals': gameEvents?.filter(e => e.scoredByTeamId === data?.awayTeamId && e.entity === 'GameEventGoal')?.length,
      'Penaltyshots': gameEvents?.filter(e => e.executedByTeamId === data?.awayTeamId && e.entity === 'GameEventPenaltyshot')?.length,
      'Saves': gameEvents?.filter(e => e.savedByTeamId === data?.awayTeamId && e.entity === 'GameEventSave')?.length,
      'FaceOffs': gameEvents?.filter(e => e.wonByTeamId === data?.awayTeamId && e.entity === 'GameEventFaceOff')?.length,
      'Penalties': gameEvents?.filter(e => e.penalizedTeamId === data?.awayTeamId && e.entity === 'GameEventPenalty')?.length,
    },
  }

  const getDefaultGoalie = (teamId) => {
    const goalies = relationsData.Lineups
      .filter(l => l.teamId == teamId)
      .filter(l => l.ListPosition?.isGoalie);
    if (goalies.length == 1) {
      return goalies[0];
    }
  }

  const createGameEvent = async (event, team, otherTeam, options) => {
    if (!isPlaying) {
      alert('Game not playing. Please select a period');
      return;
    }

    let eventCopy = { ...event };
    eventCopy.period = selectedPeriod;

    switch (event.entity) {
      case 'GameEventSave':
        eventCopy.savedByTeamId = team.teamId;
        eventCopy.savedByPlayerId = getDefaultGoalie(eventCopy.savedByTeamId)?.playerId;
        break;
      case 'GameEventGoal':
        eventCopy.scoredByTeamId = team.teamId;
        eventCopy.allowedByTeamId = otherTeam.teamId;
        eventCopy.allowedByPlayerId = getDefaultGoalie(eventCopy.allowedByTeamId)?.playerId;
        break;
      case 'GameEventPenalty':
        eventCopy.penalizedTeamId = team.teamId;
        break;
      case 'GameEventInjury':
        eventCopy.injuredTeamId = team.teamId;
        break;
      case 'GameEventFaceOff':
        eventCopy.wonByTeamId = team.teamId;
        eventCopy.lostByTeamId = otherTeam.teamId;
        break;
      case 'GameEventPenaltyshot':
        eventCopy.executedByTeamId = team.teamId;
        eventCopy.facedAgainstTeamId = otherTeam.teamId;
        break;
    }

    if (options?.fields) {
      eventCopy = {
        ...eventCopy,
        ...options.fields
      }
    }

    if (options?.silent) {

      await createGameEventRecord(eventCopy);

      gameEventsReload();

    } else {
      setModalData({
        lineups: relationsData.Lineups,
        event: eventCopy
      });
    }
  }

  const modalCancel = () => {
    setModalData(null);
  }

  const modalOk = async (event) => {
    const promise = createGameEventRecord(event);
    setModalData(null);

    await promise;
    gameEventsReload();
  }

  const isPlaying = data?.status == 'RUNNING'; // || data?.status == 'FINISHED';

  const createGameEventRecord = async (eventCopy) => {
    if (!isPlaying) {
      alert('Game not playing. Please select a period');
      return;
    }

    const progressMessageKey = Math.floor(Math.random() * 9000000) + 1000000;
    messageApi.open({
      type: 'loading',
      content: `Creating: ${eventCopy.entity}`,
      key: progressMessageKey,
      duration: 30,
    });
    setIsGameEventSaving(true)

    const record = {
      ...eventCopy,
      timestamp: new Date(),
      gameId: id,
    }

    const entity = entities[record.entity];
    await entity.create(record);

    await new Promise(r => setTimeout(r, 1000));

    messageApi.destroy(progressMessageKey);
    messageApi.open({
      type: 'success',
      content: `Created: ${eventCopy.entity}`,
      key: progressMessageKey,
    });
    setIsGameEventSaving(false)
  }

  const [elapsed, setElapsed] = useState(0);
  const [remaining, setRemaining] = useState(0);

  const updateElapsedTime = () => {
    if (!data?.startDate || !data?.startTime || !data?.endDate || !data?.endTime) {
      setElapsed(null);
      setRemaining(null);
      return;
    }

    const startDate = data.startDate;
    const startTime = data.startTime;
    const endDate = data.endDate;
    const endTime = data.endTime;

    const currentDateTime = dayjs();

    const startDateObj = dayjs(`${startDate} ${startTime}`);
    const endDateObj = dayjs(`${endDate} ${endTime}`);

    const elapsedDuration = dayjs.duration(currentDateTime.diff(startDateObj));
    const elapsedSeconds = elapsedDuration.asSeconds();

    const remainingDuration = dayjs.duration(endDateObj.diff(currentDateTime));
    const remainingSeconds = remainingDuration.asSeconds();

    const isWithinTimeframe = currentDateTime.isAfter(startDateObj) && currentDateTime.isBefore(endDateObj);

    if (isWithinTimeframe) {
      setElapsed(elapsedSeconds);
      setRemaining(remainingSeconds);
    } else {
      setElapsed(null);
      setRemaining(null);
    }
  }

  const [modal, modalContextHolder] = Modal.useModal();

  const changeSelectedPeriod = async (value) => {
    // if (data?.status == 'FINISHED') {
    //   const confirmed = await modal.confirm({
    //     title: 'Hi',
    //     contet: 'xxxx',
    //   });
    //   console.log('confirmed:', confirmed);
    //   // window.confirm('This game has already FINISHED. Are you sure do you want to switch it to RUNNING?');
    // }

    setSelectedPeriod(value);
    const status = periods.map(p => p.name).includes(value) ? 'RUNNING' : value;
    if (data?.status != status) {
      await entities.Game.patch(data?.gameId, { status });
      reload();
    }
  }

  useInterval(() => { updateElapsedTime(); }, 1000)
  useEffect(() => { updateElapsedTime(); }, [data])

  function formatTimeFromSeconds(seconds) {
    if (seconds === null) {
      return '-:--';
    }
    const duration = dayjs.duration(Math.abs(seconds), 'seconds');
    if (duration.hours() === 0) {
      return (seconds < 0 ? '-' : '') + duration.format('m:ss');
    } else {
      return (seconds < 0 ? '-' : '') + duration.format('H:mm:ss');
    }
  }

  const TeamOverview = ({ team, otherTeam, alias }) => (
    <div className="w-full">
      <Divider>{team?.name}</Divider>
      <div className="flex justify-center items-center">
        <EntityLogo entity={team} size={96} />
      </div>
      <Divider className={`my-3`}></Divider>
      <div className={`flex gap-x-2 justify-center items-center`}>
        <div className="flex gap-1">
          <div>Penalty shots:</div>
          <div className="flex flex-col items-center">
            <span className="font-bold">{(data?.overwriteEnabled && data?.[`overwrite${alias}Penalties`]) ?? stats[team?.teamId]?.['Penaltyshots']}</span>
            &nbsp;
          </div>
        </div>
        <div className="flex gap-1">
          <div>Saves:</div>
          <div className="flex flex-col items-center">
            <span className="font-bold">{(data?.overwriteEnabled && data?.[`overwrite${alias}Saves`]) ?? stats[team?.teamId]?.['Saves']}</span>
            ({stats[team?.teamId]?.['Saves']})
          </div>
        </div>
        <div className="flex gap-1">
          <div>Face-offs:</div>
          <div className="flex flex-col items-center">
            <span className="font-bold">{(data?.overwriteEnabled && data?.[`overwrite${alias}FaceOffs`]) ?? stats[team?.teamId]?.['FaceOffs']}</span>
            ({stats[team?.teamId]?.['FaceOffs']})
          </div>
        </div>
        <div className="flex gap-1">
          <div>Penalties:</div>
          <div className="flex flex-col items-center">
            <span className="font-bold">{(data?.overwriteEnabled && data?.[`overwrite${alias}Penalties`]) ?? stats[team?.teamId]?.['Penalties']}</span>
            ({stats[team?.teamId]?.['Penalties']})
          </div>
        </div>
      </div>
      <Divider className={`my-3`}></Divider>
      <EventButtons primaryTeam={team} secondaryTeam={otherTeam} />
    </div>
  )

  const EventButtons = ({ primaryTeam, secondaryTeam }) => (
    <Tooltip title={!isPlaying ? 'Please start game first by selecting a period.' : null}>
      <div className="flex gap-4 justify-center">
        <div className="flex flex-col justify-between gap-y-2">
          <Button
            disabled={!isPlaying}
            style={isPlaying ? { backgroundColor: events.find(e => e.entity == 'GameEventSave').color } : {}}
            onClick={() => createGameEvent(events.find(e => e.entity == 'GameEventSave'), primaryTeam, secondaryTeam, { silent: true })}
            className="w-20 h-[3.3rem] whitespace-pre-wrap"
            type="primary"
          >
            <span>Quick Save</span>
          </Button>
          <Button
            disabled={!isPlaying}
            style={isPlaying ? { backgroundColor: events.find(e => e.entity == 'GameEventFaceOff').color } : {}}
            onClick={() => createGameEvent(events.find(e => e.entity == 'GameEventFaceOff'), primaryTeam, secondaryTeam, { silent: true })}
            className="w-20 h-[3.3rem] whitespace-pre-wrap"
            type="primary"
          >
            <span>Quick FaceOff</span>
          </Button>
        </div>
        <div className="grid grid-cols-2 gap-x-4 gap-y-2">
          {events.map((e, idx) => (
            <Button
              key={idx}
              disabled={!isPlaying}
              style={isPlaying ? { backgroundColor: e.color } : {}}
              onClick={() => createGameEvent(e, primaryTeam, secondaryTeam)}
              className="w-32"
              type="primary">
              {e.name}
            </Button>
          ))}
        </div>
      </div>
    </Tooltip>
  )

  const Score = () => (
    <div className="flex flex-col items-center">
      <span className="font-mono text-[2.5rem] font-bold flex gap-x-2">
        <span>{data?.overwriteEnabled ? data?.overwriteHomeScore : stats[data?.homeTeamId]?.['Goals']}</span>
        <span>:</span>
        <span>{data?.overwriteEnabled ? data?.overwriteAwayScore : stats[data?.awayTeamId]?.['Goals']}</span>
      </span>
      {data?.overwriteEnabled && stats[data?.homeTeamId]?.['Goals'] &&
        <div>
          (original: {stats[data?.homeTeamId]?.['Goals']} : {stats[data?.awayTeamId]?.['Goals']})
        </div>
      }
    </div>
  )

  const TimeSlotIndicator = () => (
    <span className="font-normal">
      {data?.startDate && data?.startTime &&
        <span className="flex gap-2">
          Time Slot:
          <span className="font-bold">
            {formatDate(data?.startDate)}{' '}&nbsp;
            {formatTime(data?.startTime)}{' – '}
            {formatTime(data?.endTime)}
          </span>
        </span>
      }
      {!(data?.startDate && data?.startTime) &&
        <span>Start/End time not set.</span>
      }
    </span>
  )

  const RemainigTimeIndicator = () => (
    <span className="font-normal">
      {elapsed &&
        <span className="text-[0.85rem] flex items-center justify-end gap-x-2 w-[360px]">
          <span className="flex gap-2">
            <span className="font-mono font-bold text-right">{formatTimeFromSeconds(elapsed)}</span>
          </span>
          <Progress className="w-[200px] m-0" percent={(elapsed !== null && elapsed + remaining != 0) ? (elapsed / (elapsed + remaining) * 100) : 0} showInfo={false} />
          <span className="flex gap-2">
            <span className="font-mono font-bold text-left">{formatTimeFromSeconds(remaining ? 0 - remaining : null)}</span>
          </span>
        </span>
      }
      {!elapsed &&
        <span>Start/End time out of range.</span>
      }
    </span>
  )

  const StatusIndicator = () => (
    <span className="font-normal">
      Status:{' '}
      {data?.status && <HmsStatusTag status={data?.status} entity="Game" />}
      {!data?.status && <span>Status not set.</span>}
    </span>
  )

  return (
    <>
      {messageContextHolder}
      {modalContextHolder}
      {!isFocused && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-60 z-[5000] flex items-center justify-center">
          <div className="text-5xl text-hms-blue relative top-[-10rem]">Click to activate window</div>
        </div>
      )}
      <GameEventModal data={modalData} open={modalData} onOk={modalOk} onCancel={modalCancel} />

      <HmsFormLayout
        {... { entity, id, isLoading, isError, isNotFound, data }}
        breadcrumbs={[
          { title: 'Home', icon: icons.Home, link: '/' },
          { title: 'Games', icon: icons.Game, link: '/games' },
          { title: data?.name ?? 'Untitled Game', icon: icons.Game, link: '/games/' + data?.gameId },
          { title: 'Play Mode', icon: icons.Game },
        ]}
      >

        <Card
          size="small"
          title={<span className="flex items-center justify-between">
            <span className="">Game Play</span>
            <span>
              <TimeSlotIndicator />
            </span>
            <span>
              <RemainigTimeIndicator />
            </span>
            <span>
              <StatusIndicator />
            </span>
          </span>}>

          <Segmented
            block
            options={periodOptions}
            value={selectedPeriod}
            onChange={changeSelectedPeriod}
          />

          <div className="flex">
            <div className="flex-1 flex items-center justify-center">
              <TeamOverview team={data?.HomeTeam} otherTeam={data?.AwayTeam} alias="Home" />
            </div>
            <div className="w-32 flex items-center justify-center">
              <Score />
            </div>
            <div className="flex-1 flex items-center justify-center">
              <TeamOverview team={data?.AwayTeam} otherTeam={data?.HomeTeam} alias="Away" />
            </div>
          </div>
        </Card>

        <div className="mt-4 min-h-[calc(100vh-100px)]">
          <div className="mt-4">
            <HmsTabbedTables
              navbar={false}
              tables={[
                {
                  title: "Games Events",
                  children: <GameEvents data={gameEvents} isLoading={isGameEventSaving || gameEventsLoading || gameEventsReloading} lineups={relationsData?.Lineups} reload={gameEventsReload} homeTeam={data?.HomeTeam} awayTeam={data?.AwayTeam} />,
                },
              ]}
            />
          </div>
        </div>

      </HmsFormLayout>
    </>
  )
}

export default GamePlay

// Add the following CSS to your stylesheet
// .overlay {
//   position: fixed;
//   top: 0;
//   left: 0;
//   width: 100%;
//   height: 100%;
//   background: rgba(128, 128, 128, 0.5);
//   z-index: 9999;
// }

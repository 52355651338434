import { useEffect } from 'react';
import { enableZoomByMouseWheel, keyForZoomByMouseWheel, stepForZoomByMouseWheel } from '../IGconfig';

const useZoomByMouseWheel = (bgImgRef, bgImgZoom, setBgImgZoom) => {
  useEffect(() => {
    if (!enableZoomByMouseWheel) return;

    const handleWheel = (e) => {
      if (!e[keyForZoomByMouseWheel]) return;
      e.preventDefault();
      const newZoom = (bgImgZoom == null || isNaN(bgImgZoom) ? 0 : bgImgZoom) + (e.deltaY > 0 ? -stepForZoomByMouseWheel : stepForZoomByMouseWheel); // Adjust zoom level
      setBgImgZoom(Math.max(30, Math.min(2000, newZoom))); // Clamp zoom to some levels
    };

    const bgImgElement = bgImgRef.current;
    if (bgImgElement) {
      bgImgElement.addEventListener('wheel', handleWheel, { passive: false });
    }

    return () => {
      if (bgImgElement) {
        bgImgElement.removeEventListener('wheel', handleWheel);
      }
    };
  }, [bgImgZoom, setBgImgZoom, bgImgRef]);
};

export default useZoomByMouseWheel;
import { UploadOutlined } from '@ant-design/icons';
import { Button, message, Spin, Upload } from 'antd';
import { useState } from 'react';
import { postFileUpload } from 'utils/apiFunctions.js';

const SimpleFileUpload = ({ prefix, onUploadSuccess, acceptMime =  "image/png, image/jpeg"}) => {
    const [isUploading, setIsUploading] = useState(false);
    const handleUpload = async (file) => {
        try {
            setIsUploading(true);
            const response = await postFileUpload(file, { prefix});
            setIsUploading(false);
            console.log(response);
            if (response.status === 200) {
                const location = (await response.json()).location
                message.success(`${file.name} file uploaded successfully`);
                onUploadSuccess(location); 
            } else {
                message.error(`${file.name} file upload failed`);
            }
        } catch (error) {
            message.error(`${file.name} file upload failed`);
        }
    };

    const customRequest = ({ file, onSuccess }) => {
        handleUpload(file);
        onSuccess("ok"); // This is to trigger the antd upload success status
    };

    return (
        <Upload customRequest={customRequest} accept={acceptMime} showUploadList={false} >
            <Button icon={isUploading ? <Spin size="small" spinning={true} /> : <UploadOutlined />}>Click to Upload</Button>
        </Upload>
    );
};

export default SimpleFileUpload;
// export const defaultPhaseIdIG = '3BC7DFE0-37BF-11EE-A852-DDFA9E2BD87C'
// export const defaultGroupIdIG = '1D81EF80-37BF-11EE-A852-DDFA9E2BD87C'
// export const defaultDateIG = '2024-04-11'


export const defaultPhaseIdIG = null;
export const defaultGroupIdIG = null;
export const defaultDateIG = null;

export const dateFormatIG = 'YYYY-MM-DD';

export const enableZoomByMouseWheel = false;
export const keyForZoomByMouseWheel = 'shiftKey';
export const stepForZoomByMouseWheel = 10;

export const stepForMoveByMouse = 5;

export const createHandleBgImgMouseMove = (setBgImgPositionX, setBgImgPositionY) => (e) => {
    if (e.buttons !== 1) return; // Only move on left mouse click

    setBgImgPositionX((x) => (isNaN(x) ? 0 : x) - e.movementX);
    setBgImgPositionY((y) => (isNaN(y) ? 0 : y) + e.movementY);
}

export const createBackgroundPositionExpression = (x, y) => {
    return `right ${x}px top ${y}px`;
}



import useFetchEntities from 'hooks/useFetchEntities';
import useFetchEntity from 'hooks/useFetchEntity';
import { useMemo, useRef } from 'react';
import PhaseSelector from './Filters/PhaseSelector';
import CompetitionLogo from './Parts/CompetitionLogo';
import IGHeader from './Parts/IGHeader';

import "@fontsource/audiowide";
import "@fontsource/bebas-neue";
import "@fontsource/covered-by-your-grace";
import "@fontsource/economica";
import "@fontsource/righteous";
import "@fontsource/russo-one";

import DebugPrinter from 'components/Debug/DebugPrinter';
import DateSelector from 'components/Infographics/Filters/DateSelector';
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import usePersistentStore from 'store/persistentStore';
import { sortDateAndTime } from 'utils/sorters';
import BackgroundImgSelector from './Filters/BackgroundImgSelector';
import BackgroundPlacement from './Filters/BackgroundPlacement';
import MarginEditor from './Filters/MarginEditor';
import VenueSelector from './Filters/VenueSelector';
import useZoomByMouseWheel from './Hooks/useZoomByMouseWheel';
import { createBackgroundPositionExpression, createHandleBgImgMouseMove, dateFormatIG } from './IGconfig';
import GameScoreCard from './Parts/GameScoreCard';
dayjs.extend(customParseFormat);

const IG2_DayRecap = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const date = usePersistentStore((state) => state.get('ig-date')) ?? dayjs(new Date()).format(dateFormatIG);
  const setDate = usePersistentStore((state) => state.set('ig-date'));
  const venue = usePersistentStore((state) => state.get('ig-venue'));
  const setVenue = usePersistentStore((state) => state.set('ig-venue'));
  const bgPictureUrl = usePersistentStore((state) => state.get('ig-dayrecap-bg-url'));
  const setBgPictureUrl = usePersistentStore((state) => state.set('ig-dayrecap-bg-url'));
  const size = usePersistentStore((state) => state.get('ig-dayrecap-size'));
  const setSize = usePersistentStore((state) => state.set('ig-dayrecap-size'));
  const SIZE_PRESETS = ['PORTRAIT', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 1100, height: 1955, preset: 'PORTRAIT' };

  const bgImgPositionX = usePersistentStore((state) => state.get('ig-dayrecap-bgimgposition-x')) ?? 0;
  const setBgImgPositionX = usePersistentStore((state) => state.set('ig-dayrecap-bgimgposition-x'));
  const bgImgPositionY = usePersistentStore((state) => state.get('ig-dayrecap-bgimgposition-y')) ?? 0;
  const setBgImgPositionY = usePersistentStore((state) => state.set('ig-dayrecap-bgimgposition-y'));
  const bgImgZoom = usePersistentStore((state) => state.get('ig-dayrecap-zoom')) ?? 450;
  const setBgImgZoom = usePersistentStore((state) => state.set('ig-dayrecap-zoom'));
  const bgImgRef = useRef(null);

  const igname = 'dayrecap';
  const marginDefaults = { top: 0, left: 0, bottom: 0, right: 0 };
  const bgImgMargin = usePersistentStore((state) => state.get(`ig-${igname}-margin`)) ?? marginDefaults;
  const setBgImgMargin = usePersistentStore((state) => state.set(`ig-${igname}-margin`));
  useZoomByMouseWheel(bgImgRef, bgImgZoom, setBgImgZoom);
  const handleBgImgMouseMove = createHandleBgImgMouseMove(setBgImgPositionX, setBgImgPositionY);

  const { phaseIdsAll } = usePhaseIdTranslator(membership);
  const seasonId = membership?.seasonId;

  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');

  const venueIdsAll = venue?.venueIds == null || venue?.venueIds.length === 0 ? undefined : venue?.venueIds;

  const { data: gamesToday } = useFetchEntities(
    phaseIdsAll != null && date != null && 'Game',
    {
      filters: { startDate: date, phaseId: phaseIdsAll, venueId: venueIdsAll },
      relations: 'Venue(name,logoUrl),HomeTeam(name,logoUrl),AwayTeam(name,logoUrl),Phase>Group(name,color)',
      extraAttributes: 'HomeTeamGoals,AwayTeamGoals',
    }
  );

  const metadata = {
    SeasonName: seasonDetail?.name,
    CompetitionName: seasonDetail?.Competition?.name,
    CompetitionLogo:
      seasonDetail?.Competition?.logoUrl?.replace(
        "[size]",
        "cropped_md",
      ),
    Date: date,
  };

  const data = useMemo(() => {
    return gamesToday
      ?.sort(
        (g1, g2) => sortDateAndTime(g1.startDate, g1.startTime, g2.startDate, g2.startTime)
      )
      .map((game) => ({
        VenueName: game.Venue?.name,
        VenueLogo: game.Venue?.logoUrl?.replace("[size]", "cropped_md"),
        HomeTeamLogo: game.HomeTeam?.logoUrl?.replace("[size]", "cropped_md"),
        AwayTeamLogo: game.AwayTeam?.logoUrl?.replace("[size]", "cropped_md"),
        Score: `${game.HomeTeamGoals}:${game.AwayTeamGoals}`,
        GroupName: game.Phase?.Group?.name,
        GroupColor: game.Phase?.Group?.color,
      })) ?? [];
  }, [gamesToday]);


  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Choose date:</span>
        <div>
          <DateSelector
            value={dayjs(date, dateFormatIG)}
            onChange={(dateString) => { setDate(dateString) }}
          />
        </div>

        <span>Background image:</span>
        <div>
          <BackgroundImgSelector bgPictureUrl={bgPictureUrl} setBgPictureUrl={setBgPictureUrl} igId={2} />
        </div>

        <span className="relative top-[-14px]">Background placement:</span>
        <div >
          <BackgroundPlacement
            bgImgPositionX={bgImgPositionX}
            bgImgPositionY={bgImgPositionY}
            setBgImgPositionX={setBgImgPositionX}
            setBgImgPositionY={setBgImgPositionY}
            bgImgZoom={bgImgZoom}
            setBgImgZoom={setBgImgZoom}
          />
        </div>

        <span>Choose venue:</span>
        <div>
          <VenueSelector
            value={venue}
            onChange={(venue) => { setVenue(venue) }}
          />
        </div>

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <span>Margin:</span>
        <div className="flex gap-2 items-center">
          <MarginEditor
            margin={bgImgMargin}
            setMargin={setBgImgMargin}
            defaults={marginDefaults}
          />
        </div>

        <div />
      </div>

      {showData && (<DebugPrinter data={[metadata, gamesToday, date]}></DebugPrinter>)}

      {date && phaseIdsAll &&
        <article
          ref={exportRef}
          className="mx-auto h-[1000px] w-[1100px] bg-hockey-page-bg font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
            paddingTop: `${bgImgMargin.top}px`,
            paddingLeft: `${bgImgMargin.left}px`,
            paddingRight: `${bgImgMargin.right}px`,
            paddingBottom: `${bgImgMargin.bottom}px`,
          }}
        >
          <main className="grid h-full grid-cols-5">
            <div className="col-span-3 flex flex-col p-8 pr-0">
              <IGHeader
                className="border-b-2 border-white"
                title={`${metadata.CompetitionName} daily recap`}
                date={metadata.Date}
              />

              <div className="flex h-full flex-col items-center justify-between">
                <div className="flex flex-col gap-2">
                  {data.map((game, index) => (
                    <GameScoreCard key={index} game={game} />
                  ))}
                </div>
                <CompetitionLogo
                  src={metadata.CompetitionLogo}
                  title={metadata.CompetitionName}
                  season={metadata.SeasonName}
                />
              </div>
            </div>
            <div
              ref={bgImgRef}
              onMouseMove={handleBgImgMouseMove}
              className='w-full relative col-span-2 h-full'
              style={{
                backgroundImage: `url('${process.env.REACT_APP_IMAGE_PROXY_URL}?url=${bgPictureUrl}')`,
                backgroundSize: `${bgImgZoom ?? 100}%`, // 'cover'
                backgroundPosition: createBackgroundPositionExpression(bgImgPositionX, bgImgPositionY),              
                backgroundRepeat: 'no-repeat',
                cursor: 'grab', // Visual cue for draggable behavior
                clipPath: "polygon(50px 0, 100% 0, 100% 100%, 0% 100%)",
              }}
              onMouseDown={(e) => e.target.style.cursor = 'grabbing'} // Change cursor while dragging
              onMouseUp={(e) => e.target.style.cursor = 'grab'} // Reset cursor
            ></div>
          </main>
        </article>
      }
    </>
  )
}

export default IG2_DayRecap
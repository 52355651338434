import DebugPrinter from "components/Debug/DebugPrinter";
import SizeSelector from 'components/Infographics/Filters/SizeSelector';
import dayjs from 'dayjs';
import useFetchDataWithOrg from "hooks/useFetchDataWithOrg";
import useFetchEntity from "hooks/useFetchEntity";
import usePhaseIdTranslator from 'hooks/usePhaseIdTranslator';
import { useMemo, useRef } from "react";
import usePersistentStore from 'store/persistentStore';
import BackgroundImgSelector from "./Filters/BackgroundImgSelector";
import BackgroundPlacement from "./Filters/BackgroundPlacement";
import MarginEditor from "./Filters/MarginEditor";
import PhaseSelector from "./Filters/PhaseSelector";
import useZoomByMouseWheel from "./Hooks/useZoomByMouseWheel";
import { createBackgroundPositionExpression, createHandleBgImgMouseMove, dateFormatIG } from "./IGconfig";
import CompetitionLogo from "./Parts/CompetitionLogo";
import DotRotatedText from "./Parts/DotRotatedText";
import IGHeader from "./Parts/IGHeader";
import TablePlayers from "./Parts/TablePlayers";

const IG11_PointLeaders = ({ showData, exportRef }) => {
  const membership = usePersistentStore((state) => state.get('ig-membership'));
  const setMembership = usePersistentStore((state) => state.set('ig-membership'));
  const bgPictureUrl = usePersistentStore((state) => state.get('ig-pointleaders-bg-url'));
  const setBgPictureUrl = usePersistentStore((state) => state.set('ig-pointleaders-bg-url'));
  const size = usePersistentStore((state) => state.get('ig-pointleaders-size'));
  const setSize = usePersistentStore((state) => state.set('ig-pointleaders-size'));
  const SIZE_PRESETS = ['PORTRAIT', 'CUSTOM'];
  const DEFAULT_SIZE = { width: 625, height: 1111, preset: 'PORTRAIT' };

  const bgImgPositionX = usePersistentStore((state) => state.get('ig-pointleaders-bgimgposition-x')) ?? 0;
  const setBgImgPositionX = usePersistentStore((state) => state.set('ig-pointleaders-bgimgposition-x'));
  const bgImgPositionY = usePersistentStore((state) => state.get('ig-pointleaders-bgimgposition-y')) ?? 0;
  const setBgImgPositionY = usePersistentStore((state) => state.set('ig-pointleaders-bgimgposition-y'));
  const bgImgZoom = usePersistentStore((state) => state.get('ig-pointleaders-zoom')) ?? 100;
  const setBgImgZoom = usePersistentStore((state) => state.set('ig-pointleaders-zoom'));
  const bgImgRef = useRef(null);

  const igname = 'pointleaders';
  const marginDefaults = { top: 0, left: 0, bottom: 0, right: 0 };
  const bgImgMargin = usePersistentStore((state) => state.get(`ig-${igname}-margin`)) ?? marginDefaults;
  const setBgImgMargin = usePersistentStore((state) => state.set(`ig-${igname}-margin`));

  useZoomByMouseWheel(bgImgRef, bgImgZoom, setBgImgZoom);
  const handleBgImgMouseMove = createHandleBgImgMouseMove(setBgImgPositionX, setBgImgPositionY);


  const groupId = membership?.groupId;
  const seasonId = membership?.seasonId;
  const { phaseIdsAll } = usePhaseIdTranslator(membership);

  const { data: groupDetail } = useFetchEntity('Group', groupId, 'Season>Competition');
  const { data: seasonDetail } = useFetchEntity('Season', seasonId, 'Competition');

  const playerStats = useFetchDataWithOrg(phaseIdsAll
    ? `/admin/tablePlayersStats?phaseIds=${phaseIdsAll.join(',')}`
    : null);

  const metadata = useMemo(() => {
    return {
      GroupName: groupDetail?.name,
      GroupColor: groupDetail?.color,
      SeasonName: seasonDetail?.name,
      CompetitionName: seasonDetail?.Competition?.name,
      CompetitionLogo:
        seasonDetail?.Competition?.logoUrl?.replace(
          "[size]",
          "cropped_md",
        ),
      Date: dayjs().format(dateFormatIG),
    };
  }, [groupDetail, seasonDetail]);

  // prepare data

  const data = useMemo(() => {
    const data = playerStats?.data?.map((player) => ({
      FirstName: player.FirstName,
      LastName: player.LastName,
      PlayerLogo: player.PlayerLogo?.replace("[size]", "cropped_md"),
      PlayerLevelCode: player.PlayerLevelCode,
      TeamLogo: player.TeamLogo?.replace("[size]", "cropped_md"),
      Z: player.GP,
      G: player.G,
      A: player.A,
      P: player.P,
      TM: player.PIM,
      Stars: player.Stars,
    })) ?? [];

    const sorted = data.sort((a, b) => {
      if (a.P !== b.P) {
        return b.P - a.P;
      }
      if (a.G !== b.G) {
        return b.G - a.G;
      }
      return a.GP - b.GP;
    });

    return sorted.slice(0, 15);
  }, [playerStats]);

  return (
    <>
      <div className="grid grid-cols-[auto,1fr] gap-3 items-center">

        <span>Membership:</span>
        <PhaseSelector
          value={membership}
          onChange={setMembership}
          mandatory={['competitionId', 'seasonId']}
        />

        <span>Background image:</span>
        <div>
          <BackgroundImgSelector bgPictureUrl={bgPictureUrl} setBgPictureUrl={setBgPictureUrl} igId={11} />
        </div>

        <span className="relative top-[-14px]">Background placement:</span>
        <div >
          <BackgroundPlacement
            bgImgPositionX={bgImgPositionX}
            bgImgPositionY={bgImgPositionY}
            setBgImgPositionX={setBgImgPositionX}
            setBgImgPositionY={setBgImgPositionY}
            bgImgZoom={bgImgZoom}
            setBgImgZoom={setBgImgZoom}
          />
        </div>

        <span>Dimensions:</span>
        <div className="flex gap-2 items-center">
          <SizeSelector
            value={size}
            onChange={setSize}
            presets={SIZE_PRESETS}
            defaultValue={DEFAULT_SIZE}
          />
        </div>

        <span>Margin:</span>
        <div className="flex gap-2 items-center">
          <MarginEditor
            margin={bgImgMargin}
            setMargin={setBgImgMargin}
            defaults={marginDefaults}
          />
        </div>

        <div />
      </div>

      {showData && (<DebugPrinter data={[groupDetail, playerStats, data, metadata]}></DebugPrinter>)}
      {playerStats && phaseIdsAll &&
        <article
          ref={exportRef}
          className="mx-auto w-[625px] bg-hockey-page-bg font-header text-white"
          style={{
            width: `${size?.width}px`,
            height: `${size?.height}px`,
            paddingTop: `${bgImgMargin.top}px`,
            paddingLeft: `${bgImgMargin.left}px`,
            paddingRight: `${bgImgMargin.right}px`,
            paddingBottom: `${bgImgMargin.bottom}px`,
          }}
        >

          <div className="flex h-full flex-col items-center justify-between">

            <div className="p-8">
              <IGHeader
                title={`League leaders`}
                league={metadata.GroupName}
                leagueStyle={{ color: metadata.GroupColor ?? '#777777' }}
                small
                date={metadata.Date}
              />
              <TablePlayers data={data} highlightColID="p" />
            </div>

            <div className='w-full'>
              <DotRotatedText firstText="Points" secondText="Leaders" />

              <div
                ref={bgImgRef}
                onMouseMove={handleBgImgMouseMove}
                className='w-full z-20 relative aspect-[24/10] top-[-22px]'
                style={{
                  backgroundImage: `url('${process.env.REACT_APP_IMAGE_PROXY_URL}?url=${bgPictureUrl}')`,
                  backgroundSize: `${bgImgZoom ?? 100}%`,
                  backgroundPosition: createBackgroundPositionExpression(bgImgPositionX, bgImgPositionY),
                  backgroundRepeat: 'no-repeat',
                  cursor: 'grab', // Visual cue for draggable behavior
                  clipPath: "polygon(450px 0, 100% 0, 100% 100%, 0 100%, 0 70px)",
                }}
                onMouseDown={(e) => e.target.style.cursor = 'grabbing'} // Change cursor while dragging
                onMouseUp={(e) => e.target.style.cursor = 'grab'} // Reset cursor
              ></div>
              <CompetitionLogo
                className="mx-auto scale-75 p-4 relative top-[-22px]"
                src={metadata.CompetitionLogo}
                title={metadata.CompetitionName}
                season={metadata.SeasonName}
              />
            </div>
          </div>
        </article>
      }
    </>
  );
};

export default IG11_PointLeaders;


export const entitiesDontNeedOrganizationId = ['Organization', 'User', 'Lineup', 'GameStar', 'Attachment', 'ChangeRequest'];
export const BASE_URL = process.env.REACT_APP_BACKEND_BASE_URL;

export async function postEntity(ORGANIZATION_ID, entity, data){
    if (entitiesDontNeedOrganizationId.indexOf(entity.name) == -1) {
        data = { ...data, 'organizationId': ORGANIZATION_ID };
    }
    const response = await fetch(`${BASE_URL}/admin/entity?entity=${entity.name}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return {
        status: response.status,
        body: await response.json(),
    }
}

export async function postEntities(ORGANIZATION_ID, entity, data){
    if (entitiesDontNeedOrganizationId.indexOf(entity.name) == -1) {
        data = data.map(r => ({ ...r, 'organizationId': ORGANIZATION_ID }));
    }
    const response = await fetch(`${BASE_URL}/admin/entities?entity=${entity.name}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return {
        status: response.status,
        body: await response.json(),
    }
}

export async function patchEntity(ORGANIZATION_ID, entity, id, data){
    if (entitiesDontNeedOrganizationId.indexOf(entity.name) == -1) {
        data = { ...data, 'organizationId': ORGANIZATION_ID };
    }
    const response = await fetch(`${BASE_URL}/admin/entity?entity=${entity.name}&id=${id}`, {
        method: 'PATCH',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return {
        status: response.status,
        body: await response.json(),
    }
}

export async function putEntity (ORGANIZATION_ID, entity, data){
    if (entitiesDontNeedOrganizationId.indexOf(entity.name) == -1) {
        data = { ...data, 'organizationId': ORGANIZATION_ID };
    }
    const response = await fetch(`${BASE_URL}/admin/entity?entity=${entity.name}&id=${data[entity.primaryKey]}`, {
        method: 'PUT',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });

    return {
        status: response.status,
        body: await response.json(),
    }
}

export async function deleteEntity(entity, id){
    const response = await fetch(`${BASE_URL}/admin/entity?entity=${entity.name}&id=${id}`, {
        method: 'DELETE',
        credentials: 'include',
    });
    return {
        status: response.status,
    }
}

export async function cloneEntity(entity, id, data){
    const response = await fetch(`${BASE_URL}/admin/cloneEntity?entity=${entity.name}&id=${id}`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
    return {
        status: response.status,
        body: await response.json(),
    }
}

export async function deleteEntities(entity, ids){
    const response = await fetch(`${BASE_URL}/admin/entities?entity=${entity.name}`, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ids: ids }),
    });
    return {
        status: response.status,
    }
}

export async function postRelation(sourceEntity, sourceEntityId, targetEntity, targetEntityIds, targetEntityAs){
    const url = `${BASE_URL}/admin/entityRelation?` +
        `sourceEntity=${sourceEntity.name}&` +
        `sourceEntityId=${sourceEntityId}&` +
        `targetEntity=${targetEntity.name}&` +
        `targetEntityAs=${targetEntityAs ?? targetEntity.name}&` +
        `targetEntityId=${Array.isArray(targetEntityIds) ? targetEntityIds.join(',') : targetEntityIds}`;

    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return {
        status: response.status,
        body: await response.json(),
    }
}

export async function deleteRelation(sourceEntity, sourceEntityId, targetEntity, targetEntityIds, targetEntityAs){
    const url = `${BASE_URL}/admin/entityRelation?` +
        `sourceEntity=${sourceEntity.name}&` +
        `sourceEntityId=${sourceEntityId}&` +
        `targetEntity=${targetEntity.name}&` +
        `targetEntityAs=${targetEntityAs ?? targetEntity.name}&` +
        `targetEntityId=${Array.isArray(targetEntityIds) ? targetEntityIds.join(',') : targetEntityIds}`;

    const response = await fetch(url, {
        method: 'DELETE',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
    });
    return {
        status: response.status,
        body: await response.json(),
    }
}

export async function postSchedulerGenerateGames(id){
    const response = await fetch(`${BASE_URL}/scheduler/generateGames?id=${id}`, {
        method: 'POST',
        credentials: 'include',
    });
    return {
        status: response.status,
        body: await response.json(),
    }
}

export async function postLogo(blob, parameters){
    const urlParams = new URLSearchParams(parameters);
    const formData = new FormData();
    formData.append('file', blob);
    
    return await fetch(`${BASE_URL}/admin/logo?${urlParams.toString()}`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
}

export async function patchLogo(parameters){
    const urlParams = new URLSearchParams(parameters);
    
    return await fetch(`${BASE_URL}/admin/logo?${urlParams.toString()}`, {
      method: 'PATCH',
      credentials: 'include',
    });
}

export async function deleteLogo(parameters){
    const urlParams = new URLSearchParams(parameters);
    
    return await fetch(`${BASE_URL}/admin/logo?${urlParams.toString()}`, {
      method: 'DELETE',
      credentials: 'include',
    });
}

export async function postAttachment(blob, parameters){
    const urlParams = new URLSearchParams(parameters);
    const formData = new FormData();
    formData.append('file', blob);
    
    return await fetch(`${BASE_URL}/admin/attachment?${urlParams.toString()}`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
}

export async function deleteAttachment(parameters) {
    const urlParams = new URLSearchParams(parameters);
    
    return await fetch(`${BASE_URL}/admin/attachment?${urlParams.toString()}`, {
      method: 'DELETE',
      credentials: 'include',
    });
}

export async function sendInvite(data) {
    return await fetch(`${BASE_URL}/admin/sendInvite`, {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
    });
}

export async function postFileUpload(blob, parameters){
    const urlParams = new URLSearchParams(parameters);
    const formData = new FormData();
    formData.append('file', blob);
    
    return await fetch(`${BASE_URL}/admin/fileUpload?${urlParams.toString()}`, {
      method: 'POST',
      body: formData,
      credentials: 'include',
    });
}
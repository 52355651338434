import { Button, Modal, Radio } from 'antd';
import GameEventDuration from 'components/Modals/GameEvent/GameEventDuration';
import GameEventListSelect from 'components/Modals/GameEvent/GameEventListSelect';
import GameEventLocationGroupSelect from 'components/Modals/GameEvent/GameEventLocationGroupSelect';
import GameEventPeriodSelect from 'components/Modals/GameEvent/GameEventPeriodSelect';
import GameEventPlayerSelect from 'components/Modals/GameEvent/GameEventPlayerSelect';
import GameEventTimeSelect from 'components/Modals/GameEvent/GameEventTimeSelect';
import useHotKey from 'hooks/useHotKey';
import useLists from 'hooks/useLists';
import { useEffect, useRef, useState } from 'react';
import usePersistentStore from 'store/persistentStore';
import { formatTimeString } from 'utils/timeString';

const GameEventModal = ({ data: initialData, open, onOk, onCancel }) => {
    const {
        ListShotType,
        ListGoalType,
        ListGoalSubtype,
        ListPenaltyType,
        ListPenaltySubtype,
        ListInjuryType } = useLists();
    const autoFocusInputRef = useRef(null);
    const [data, setData] = useState({});
    const [isBusy, setIsBusy] = useState(false);
    const [fieldsValidity, setFieldsValidity] = useState({});
    const okButtonRef = useRef(null);

    const eventName = data?.event?.entity?.replace('GameEvent', '');

    const workspaceType = usePersistentStore((state) => state.get(`gameevent-wsType`)) ?? 'mouse';
    const setWorkspaceType = usePersistentStore((state) => state.set(`gameevent-wsType`));

    const setValidity = (field, status) => {
        setFieldsValidity(fieldsValidity => ({
            ...fieldsValidity,
            [field]: status,
        }));
    }

    useHotKey('s', (e) => {
        if (open && (e.ctrlKey || e.metaKey) && e.shiftKey) {
            handleOk();
        }
    });
    
    useHotKey('S', (e) => {
        if (open && (e.ctrlKey || e.metaKey) && e.shiftKey) {
            handleOk();
        }
    });

    const handleOk = () => {
        if (!isBusy) {
            const isValid = Object.keys(fieldsValidity).length == 0 || Object.values(fieldsValidity).every(v => v);
            if (isValid) {
                setIsBusy(true);
                onOk(data.event);
            } else {
                autoFocusInputRef.current?.focus();
            }
        }
    }

    const handleCancel = () => {
        if (!isBusy) {
            setIsBusy(true);
            onCancel();
        }
    }

    useEffect(() => {
        setData(initialData);
        setIsBusy(false);
    }, [initialData]);

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                autoFocusInputRef.current?.focus();
            })
        }
    }, [open])

    const onValueChange = (key, value) => {
        // console.log(key, '=', value);

        setData((data) => ({
            ...data,
            event: {
                ...data?.event,
                [key]: value,
            }
        }));

        if (key == 'listPenaltyTypeId') {
            const penaltyType = ListPenaltyType.find(p => p.listPenaltyTypeId == value);
            const penaltyDuration = penaltyType?.duration;
            const penaltyDurationString = penaltyDuration ? formatTimeString(penaltyDuration * 60) : '';
            setData((data) => ({
                ...data,
                event: {
                    ...data?.event,
                    duration: penaltyDurationString,
                }
            }));
        }
    }

    const toggleValueChange = (key, value) => {
        onValueChange(key, value);
    }

    const lineups = data?.lineups;
    if (lineups) {
        lineups.sort((a, b) => (a.number ?? 999) - (b.number ?? 999));
    }

    const getPlayerOptions = (teamId, fieldName, connectedFields = [], onlyGoalie) => {
        return lineups
            .filter(l => l.teamId == teamId)
            .filter(l => !onlyGoalie || l.ListPosition?.isGoalie)
            .filter(l => workspaceType == 'keyboard' || !data.event[fieldName] || data.event[fieldName] == l.playerId)
            .map(l => ({
                number: l.number,
                name: l.Player?.firstName + ' ' + l.Player?.lastName,
                value: l.playerId,
                disabled: connectedFields.some(f => l.playerId == data?.event?.[f]),
            }))
    }

    const getListOptions = (list, fieldName, parentFieldName) => {
        console.log('getListOptions', list, fieldName, parentFieldName);
        const options = list
            .filter(l => workspaceType == 'keyboard' || !parentFieldName || data.event[parentFieldName] == l[parentFieldName] || l[parentFieldName] == null)
            .map(l => ({
                label: l.name,
                value: l[fieldName],
                priority: l.priority,
            }))
            .filter(opt => workspaceType == 'keyboard' || !data.event[fieldName] || data.event[fieldName] == opt.value);
        options.sort((a, b) => (a.priority ?? 9999) - (b.priority ?? 9999));
        return options;
    }

    return (
        <>
            <Modal
                title={<>
                    Game Event {eventName}
                    <div className="text-sm float-right text-gray-500 font-normal relative top-[-4px]">
                        <span className='inline-block relative top-[1px]'>Optimize for:</span>
                        <Radio.Group
                            className=" ml-2 mr-8 float-right font-normal"
                            block
                            onChange={(e) => setWorkspaceType(e.target.value)}
                            options={[{ label: 'Mouse', value: 'mouse' },{ label: 'Keyboard', value: 'keyboard' }]}
                            defaultValue={workspaceType}
                            optionType="button"
                            buttonStyle="solid"
                            size='small'
                        />
                    </div>
                </>}
                open={open}
                footer={null}
                onCancel={handleCancel}
                transitionName=""
                centered
                width={1000}
                destroyOnClose={true}
            >


                {(open && data && data.lineups) &&
                    <>
                        <div className="h-5" />

                        <div className="grid gap-y-6 grid-cols-1 items-start">

                            <GameEventPeriodSelect {...{ autoFocusInputRef, data, onValueChange }} />

                            <GameEventTimeSelect {...{ autoFocusInputRef, data, onValueChange, setValidity }} />

                            {(data?.event?.entity == 'GameEventGoal') &&
                                <div className="grid gap-y-6">
                                    <GameEventPlayerSelect workspaceType={workspaceType} label="Scored by" name="scoredByPlayerId" options={getPlayerOptions(data?.event?.scoredByTeamId, 'scoredByPlayerId', ['assistedBy1PlayerId', 'assistedBy2PlayerId'])} data={data} toggleValueChange={toggleValueChange} />
                                    <GameEventPlayerSelect workspaceType={workspaceType} label="Assisted by" name="assistedBy1PlayerId" options={getPlayerOptions(data?.event?.scoredByTeamId, 'assistedBy1PlayerId', ['scoredByPlayerId', 'assistedBy2PlayerId'])} data={data} toggleValueChange={toggleValueChange} />
                                    <GameEventPlayerSelect workspaceType={workspaceType} label="Second assist" name="assistedBy2PlayerId" options={getPlayerOptions(data?.event?.scoredByTeamId, 'assistedBy2PlayerId', ['assistedBy1PlayerId', 'scoredByPlayerId'])} data={data} toggleValueChange={toggleValueChange} />
                                    <GameEventPlayerSelect workspaceType={workspaceType} label="Allowed by" name="allowedByPlayerId" options={getPlayerOptions(data?.event?.allowedByTeamId, 'allowedByPlayerId', [], true)} data={data} toggleValueChange={toggleValueChange} noOptionsText="No Goalies defined for this team" />
                                    <GameEventListSelect workspaceType={workspaceType} label="Shot type" name="listShotTypeId" options={getListOptions(ListShotType, 'listShotTypeId')} data={data} toggleValueChange={toggleValueChange} />
                                    <GameEventListSelect workspaceType={workspaceType} label="Goal type" name="listGoalTypeId" options={getListOptions(ListGoalType, 'listGoalTypeId')} data={data} toggleValueChange={toggleValueChange} />
                                    <GameEventListSelect workspaceType={workspaceType} label="Goal subtype" name="listGoalSubtypeId" options={getListOptions(ListGoalSubtype, 'listGoalSubtypeId', 'listGoalTypeId')} data={data} toggleValueChange={toggleValueChange} noOptionsText="No subtypes for the selected goal type" />
                                    <GameEventLocationGroupSelect data={data} toggleValueChange={toggleValueChange} />
                                </div>
                            }

                            {(data?.event?.entity == 'GameEventSave') &&
                                <GameEventPlayerSelect workspaceType={workspaceType} label="Saved by" name="savedByPlayerId" options={getPlayerOptions(data?.event?.savedByTeamId, 'savedByPlayerId', [], true)} data={data} toggleValueChange={toggleValueChange} noOptionsText="No Goalies defined for this team" />
                            }

                            {(data?.event?.entity == 'GameEventPenalty') &&
                                <div className="grid gap-y-6">
                                    <GameEventPlayerSelect workspaceType={workspaceType} label="Penalized player" name="penalizedPlayerId" options={getPlayerOptions(data?.event?.penalizedTeamId, 'penalizedPlayerId')} data={data} toggleValueChange={toggleValueChange} />
                                    <GameEventListSelect workspaceType={workspaceType} label="Penalty type" name="listPenaltyTypeId" options={getListOptions(ListPenaltyType, 'listPenaltyTypeId')} data={data} toggleValueChange={toggleValueChange} />
                                    <GameEventDuration name="duration" data={data} />  {/* onValueChange={onValueChange} /> */}
                                    <GameEventListSelect workspaceType={workspaceType} label="Penalty subype" name="listPenaltySubtypeId" options={getListOptions(ListPenaltySubtype, 'listPenaltySubtypeId', 'listPenaltyTypeId')} data={data} toggleValueChange={toggleValueChange} placeholder={data?.event?.listPenaltyTypeId == null ? 'Please select Penalty Type' : null} />
                                </div>
                            }

                            {(data?.event?.entity == 'GameEventInjury') &&
                                <div className="grid gap-y-6">
                                    <GameEventPlayerSelect workspaceType={workspaceType} label="Injured player" name="injuredPlayerId" options={getPlayerOptions(data?.event?.injuredTeamId, 'injuredPlayerId')} data={data} toggleValueChange={toggleValueChange} />
                                    <GameEventListSelect workspaceType={workspaceType} label="Injury type" name="listInjuryTypeId" options={getListOptions(ListInjuryType, 'listInjuryTypeId')} data={data} toggleValueChange={toggleValueChange} />
                                </div>
                            }

                            {(data?.event?.entity == 'GameEventFaceOff') &&
                                <div className="grid gap-y-6">
                                    <GameEventPlayerSelect workspaceType={workspaceType} label="Won by" name="wonByPlayerId" options={getPlayerOptions(data?.event?.wonByTeamId, 'wonByPlayerId')} data={data} toggleValueChange={toggleValueChange} />
                                    <GameEventPlayerSelect workspaceType={workspaceType} label="Lost by" name="lostByPlayerId" options={getPlayerOptions(data?.event?.lostByTeamId, 'lostByPlayerId')} data={data} toggleValueChange={toggleValueChange} />
                                </div>
                            }

                            {(data?.event?.entity == 'GameEventPenaltyshot') &&
                                <div className="grid gap-y-6">
                                    <GameEventPlayerSelect workspaceType={workspaceType} label="Executed by" name="executedByPlayerId" options={getPlayerOptions(data?.event?.executedByTeamId, 'executedByPlayerId')} data={data} toggleValueChange={toggleValueChange} />
                                    <GameEventPlayerSelect workspaceType={workspaceType} label="Faced against" name="facedAgainstPlayerId" options={getPlayerOptions(data?.event?.facedAgainstTeamId, 'facedAgainstPlayerId', [], true)} data={data} toggleValueChange={toggleValueChange} noOptionsText="No Goalies defined for this team" />
                                </div>
                            }

                            <div className="flex justify-end gap-3">
                                <Button ref={okButtonRef} type="primary" className="min-w-[100px] flex items-center justify-center" disabled={isBusy} onClick={handleOk}>
                                    OK
                                </Button>
                                <Button className="min-w-[100px]" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </div>

                        </div>
                    </>
                }
            </Modal>
        </>
    )
}

export default GameEventModal
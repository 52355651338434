import XyEditor from './XyEditor';
import ZoomEditor from './ZoomEditor';

const BackgroundPlacement = ({ bgImgPositionX, bgImgPositionY, setBgImgPositionX, setBgImgPositionY, bgImgZoom, setBgImgZoom }) => {
  return (
    <div className='flex flex-col gap-2'>
      <div className='flex gap-6 items-center'>
        <XyEditor
          xValue={bgImgPositionX}
          yValue={bgImgPositionY}
          setXValue={setBgImgPositionX}
          setYValue={setBgImgPositionY}
        />
        <ZoomEditor zoomLevel={bgImgZoom} setZoomLevel={setBgImgZoom} />
      </div>
      <div className='self-start text-gray-500 text-sm italic'>Use drag & drop to move the image</div>

    </div>
  );
};

export default BackgroundPlacement;